<template lang="pug">
  .grid.is-small.is-middle
    template(v-for="chunk in chunks")
      .column(v-for="item in chunk" :class="`is-desktop-${item.size}`")
        slot(v-if="item.data" :size="item.size" :ratio="item.ratio" :item="item.data")

</template>

<script>
/*eslint-disable */
export default {
  name: 'PsudoRandomGrid',
  props: {
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    chunkSizes: {
      type: Array,
      default: () => [
        [6, 1, 5],
        [1, 4, 1, 6],
        [7, 1, 4],
        [5, 1, 6],
        [7, 1, 4]
      ]
    },
    chunkRatios: {
      type: Array,
      default: () => [
        ['2:1', '1:1'],
        ['1:1', '2:1'],
        ['3:2', '3:2'],
        ['3:2', '3:2'],
        ['3:2', '1:1']
      ]
    }
  },
  data: () => ({
    chunks: []
  }),
  watch: {
    items(val) {
      this.chunks = Object.freeze(this.chunkArray(val, 2));
    }
  },
  created() {
    this.chunks = Object.freeze(this.chunkArray(this.items, 2));
  },
  methods: {
    chunkIdx(n) {
      return Math.floor((n + 1) / 2);
    },
    chunkArray(items, chunkSize) {
      // make up a copy cause we gonna splice things up
      const copy = [...items];
      const chunks = [];

      // first build up the chunks.
      const numberOfChunks = Math.ceil(copy.length / chunkSize);
      for (let i = 0; i < numberOfChunks; i++) {
        const data = copy.splice(0, chunkSize);

        let chunkIdx = 0;
        chunks.push(
          this.chunkSizes[i % 5].map(size => {
            const item = { size };
            if (size >= 4) {
              item.data = data[chunkIdx];
              item.ratio = this.chunkRatios[i % 5][chunkIdx];
              chunkIdx++;
            }

            return item;
          })
        );
      }

      return chunks;
    },
    push(items) {
      const newChunks = this.chunkArray(items, 2, this.chunks);

      this.chunks = Object.freeze([...this.chunks, ...newChunks]);
    }
  }
};
</script>
