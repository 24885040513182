<template lang="pug">
  div
    section.Intro
      AppTitle.Intro-title(:title="$t('intro')")
      img.Intro-img(src="https://cdn.baianat.com/static/intro.png" :alt="$t('introImg')")

    section.u-video-max
      div(style='padding:56.25% 0 0 0;position:relative;')
        iframe(src='https://player.vimeo.com/video/681556253?h=cbfd41d7d3&color=f42828&title=0&byline=0&portrait=0' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen; picture-in-picture'  allowfullscreen='')
      script(src='https://player.vimeo.com/api/player.js')

    //- section.u-img-max
    //-   AppImg(v-if="$i18n.locale === 'en'" :srcset="randomImage[Math.floor((Math.random() * 3) + 0)]" ratio="5:2" :alt="$t('pageTitle')")
    //-   AppImg(v-else :srcset="randomImageAr[Math.floor((Math.random() * 4) + 0)]" ratio="5:2" :alt="$t('pageTitle')")

    section.u-mt-md
      .grid.is-desktop-4.is-6.homeServices
        .column.u-mb-sm(v-for="item in $t('capabilities.items')")
          h4 #[AppLink(:to="item.url" :title="item.title" )  {{item.title}} ]
          p {{item.desc}}

    section.u-mt-md
      AppTitle.u-mb-xs(:title="$t('works')" tag="h2")
      AsymetricGrid(:items="works" ref="itemsViewer" v-slot="{ item, ratio }")
        AppLink(v-if="item.isLink" to="/work" :title="$t('fullWorks').replace(/<br>/g,'')" class="button-link is-massive " v-html="$t('fullWorks')")
        AppCard.is-work(v-else :img="[{ srcset: item.thumb, type: 'image/jpg', ratio }]" :ratio="ratio" :title="item.title" :url="item.url ? item.url : `/work/${item.slug}`")

    section.u-mt-md
      AppTitle.u-mb-0(:title="$t('mentality')" tag="h2")

    section.u-mt-md
      .grid.is-small.is-middle
        .column.is-desktop-6
          AppCard.is-large( :img="[{ srcset: '{CDN_URL}/labs@2x.webp 2x, {CDN_URL}/labs.webp 1x', type: 'image/webp' },  { srcset: '{CDN_URL}/labs@2x.png 2x, {CDN_URL}/labs.png 1x', type: 'image/png' }]" :excerpt="$t('labsDesc')" ratio="2:1" :title="$t('labs')" url="/labs")
        .column.is-desktop-1
        .column.is-desktop-4
          AppCard.is-large( :img="[{ srcset: '{CDN_URL}/academy@2x.webp 2x, {CDN_URL}/academy.webp 1x', type: 'image/webp' },  { srcset: '{CDN_URL}/academy@2x.png 2x, {CDN_URL}/academy.png 1x', type: 'image/png' }]" :excerpt="$t('academyDesc')" ratio="1:1" :title="$t('academy')" url="/academy")
        .column.is-desktop-1

    //- section.u-mt-md
    //-   AppTitle.u-mb-md(:title="$t('dive')" tag="h2")
    //-   .grid.is-desktop-4
    //-     .column
    //-       AppCard(:img="[{ srcset: '{CDN_URL}/home/hubs@2x.webp 2x, {CDN_URL}/home/hubs.webp 1x', type: 'image/webp' },  { srcset: '{CDN_URL}/home/hubs@2x.png 2x, {CDN_URL}/home/hubs.png 1x', type: 'image/png' }]"  :title="$t('hubs')" url="/hubs" ratio="1-1")
    //-     .column
    //-       AppCard(:img="[{ srcset: '{CDN_URL}/home/books@2x.webp 2x, {CDN_URL}/home/books.webp 1x', type: 'image/webp' },  { srcset: '{CDN_URL}/home/books@2x.png 2x, {CDN_URL}/home/books.png 1x', type: 'image/png' }]"  :title="$t('learn')" url="/learn" ratio="1-1")
    //-     .column
    //-       AppCard(:img="[{ srcset: '{CDN_URL}/home/tools@2x.webp 2x, {CDN_URL}/home/tools.webp 1x', type: 'image/webp' },  { srcset: '{CDN_URL}/home/tools@2x.png 2x, {CDN_URL}/home/tools.png 1x', type: 'image/png' }]"  :title="$t('inside')" url="/inside" ratio="1-1")

    section.Ventures.u-mh-mi
      AppTitle.u-mb-md(:title="$t('ventures')" tag="h2")
      ul.Ventures-list
        li.u-mb-xs(v-for="v,idx in $t('venturesItems')")
          AppLink(to="/brands")
            AppImg(:srcset="[{ srcset: `{CDN_URL}/home/ventures/${v}@2x.png 2x, {CDN_URL}/home/ventures/${v}.png 1x`, type: 'image/png' }]" ratio="5:2" :alt="v")


</template>

<script>
import AsymetricGrid from '~/components/PsudoRandomGrid';
import { Works } from '~/graphql/Work.gql';

export default {
  scrollToTop: true,
  head() {
    const $t = this.$t.bind(this);
    return {
      title: this.$t('pageTitle'),
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: $t('pageTitle')
        },
        {
          hid: 'description',
          name: 'description',
          content: $t('pageDesc')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: $t('pageDesc')
        }
      ]
    };
  },
  async asyncData({ app }) {
    try {
      const { data } = await app.$api.query({
        query: Works,
        variables: {
          first: 5
        }
      });

      const items = data.works.edges.map(e => e.node);
      items.push({
        isLink: true
      });

      return {
        works: items
      };
    } catch (err) {
      console.error(err);
      return {
        works: []
      };
    }
  },
  components: {
    AsymetricGrid
  },
  data: () => ({
    randomImage: [
      [
        {
          srcset: '{CDN_URL}/home/strategy-comes-first@2x.webp 2x, {CDN_URL}/home/strategy-comes-first.webp 1x',
          type: 'image/webp'
        },
        {
          srcset: '{CDN_URL}/home/strategy-comes-first@2x.png 2x, {CDN_URL}/home/strategy-comes-first.png 1x',
          type: 'image/png'
        }
      ],
      [
        {
          srcset:
            '{CDN_URL}/home/innovation-fuels-business@2x.webp 2x, {CDN_URL}/home/innovation-fuels-business.webp 1x',
          type: 'image/webp'
        },
        {
          srcset: '{CDN_URL}/home/innovation-fuels-business@2x.png 2x, {CDN_URL}/home/innovation-fuels-business.png 1x',
          type: 'image/png'
        }
      ],
      [
        {
          srcset: '{CDN_URL}/home/thinking-critically@2x.webp 2x, {CDN_URL}/home/thinking-critically.webp 1x',
          type: 'image/webp'
        },
        {
          srcset: '{CDN_URL}/home/thinking-critically@2x.png 2x, {CDN_URL}/home/thinking-critically.png 1x',
          type: 'image/png'
        }
      ]
    ],
    randomImageAr: [
      [
        {
          srcset: '{CDN_URL}/home/ar/strategy-comes-first@2x.webp 2x, {CDN_URL}/home/ar/strategy-comes-first.webp 1x',
          type: 'image/webp'
        },
        {
          srcset: '{CDN_URL}/home/ar/strategy-comes-first@2x.png 2x, {CDN_URL}/home/ar/strategy-comes-first.png 1x',
          type: 'image/png'
        }
      ],
      [
        {
          srcset:
            '{CDN_URL}/home/ar/innovation-fuels-business@2x.webp 2x, {CDN_URL}/home/ar/innovation-fuels-business.webp 1x',
          type: 'image/webp'
        },
        {
          srcset:
            '{CDN_URL}/home/ar/innovation-fuels-business@2x.png 2x, {CDN_URL}/home/ar/innovation-fuels-business.png 1x',
          type: 'image/png'
        }
      ],
      [
        {
          srcset: '{CDN_URL}/home/ar/thinking-critically@2x.webp 2x, {CDN_URL}/home/ar/thinking-critically.webp 1x',
          type: 'image/webp'
        },
        {
          srcset: '{CDN_URL}/home/ar/thinking-critically@2x.png 2x, {CDN_URL}/home/ar/thinking-critically.png 1x',
          type: 'image/png'
        }
      ]
    ]
  }),
  mounted() {
    if (this.$route.query.action === 'confirmSubscriber') {
      this.$store.dispatch('alert/success', 'Thanks for subscribing, you will get our newsletters shortly.');
    }
  },
  methods: {
    normalizeURL(url) {
      if (process.server) {
        return url.replace(process.env.APP_URL, '');
      }

      return url.replace(location.href, '');
    }
  }
};
</script>

<style lang="stylus">
.Intro
  padding: unitRes(30, 160) 0 unitRes(60, 160)
  position: relative

.Intro-title
  line-height: 1
  margin: 0

  +mq($until: 'widescreen')
    font-size: unitRes(36, 160)

.Intro-img
  position: absolute
  top: 0
  right: 0
  z-index: -1

  +mq($until: 'desktop')
    display: none

div.grid.is-6.homeServices
  +mq($until: 440px)
    .column
      flex: 0 0 100%
      min-width: 100%

.Ventures-list
  list-style-type: none
  display: flex
  flex-wrap: wrap
  margin: 0 unitRes(-20, -40)
  justify-content: space-between

  li
    // flex: 0 0 calc((100% / 6))
    // width: calc((100% / 6))
    padding-right: unitRes(20, 40)
    padding-left: unitRes(20, 40)
    display: block
    align-items: center

    >a
      width: 200px
      display: block

      +mq($until: 1850px)
        width: 160px

      +mq($until: 1510px)
        width: 130px

      +mq($until: 1270px)
        width: 100px
</style>

<i18n>
{
  "en": {
    "pageTitle":"Baianat",
    "pageDesc":"Baianat is a multidisciplinary company working in many fields including web design, branding, type design, business, and advertising.",
    "intro":"Authentic solutions |through digital |innovation.",
    "introImg":"Authentic solutions through digital innovation",
    "services": "Unleash your |full business |potentials.",
    "ourServices": "Our services",
    "seeOurServices": "See Our services",
    "business":"Business",
    "businessDesc":"Establish a solid foundation for your business, overcome risks, and strategize your marketing efforts.",
    "branding":"Branding",
    "brandingDesc":"Developing unique brand identity systems that stick in minds, and strategically distinguish your business.",
    "interaction":"Interaction",
    "interactionDesc":"Creating humanized interfaces, and design systems that win hearts, and support business goals.",
    "development":"Development",
    "developmentDesc":"Web, mobile, and desktop solutions backed with security, and support to help you kick start your online presence.",
    "animation":"Animation",
    "animationDesc":"Using 3D powers to design products, create advertising campaigns, and make short movies.",
    "technology":"Technology",
    "technologyDesc":"Next-gen solutions to unlock new possibilities for your business, and facilitate your daily activities.",
    "works":"Our work and |success partners.",
    "fullWorks":"See all <br>our work",
    "labs":"Labs",
    "labsDesc":"Enjoy Baianat educational materials in different fields from courses to books, podcasts, articles and more.",
    "atlas":"Atlas",
    "atlasDesc":"Explore projects and tools made by Baianaters for the world.",
    "mentality":"Multidisciplinary with |detail oriented |mentality.",
    "ventures":"Some of our |partners & brands.",
    "venturesItems":{
      "1":"nllo",
      "2":"sbont",
      "3":"basb",
      "4":"sellesa",
      "5":"obka",
      "6":"halx"
    },
    "hubs":"Hubs",
    "books":"Books",
    "tools":"Tools",
    "dive":"Dive deeper in |different fields.",
    "capabilities":{
      "title":"Unleash your |full business |potentials.",
      "url":"/capabilities",
      "cta":"Our Capabilities",
      "items":{
        "1":{
          "title":"Solutions",
          "desc":"Delivering solutions that sets you apart from your competition and closer to your clients.",
          "url":"/solutions"
        },
        "2":{
          "title":"Services",
          "desc":"Unleash your full business potential with services in business, design, development and technology.",
          "url":"/services"
        },
        "3":{
          "title":"Industries",
          "desc":"Set the standards of your industry by integrating technology into your operations.",
          "url":"/industries"
        }
      }
    },
    "inside":"Inside",
    "learn":"Learn",
    "learnDesc":"Follow your passion to new places with our learning paths.",
    "academy":"Academy",
    "academyDesc":"Learning paths in design, business, development and technology."
  },
  "ar": {
    "pageTitle":"بيانات",
    "pageDesc":"بيانات هي شركة متعددة التخصصات تعمل في مجالات تصميم المواقع والهوية التجارية والبرمجة والأعمال التجارية و الانيميشن.",
    "intro":"حلول حقيقية من خلال |الابتكارات الرقمية.",
    "introImg":"حلول حقيقية من خلال الابتكارات الرقمية.",
    "services": "أطلق العنان |للإمكانات الكاملة |لعملك.",
    "ourServices": "خدماتنا",
    "seeOurServices": "شاهد خدماتنا",
    "business":"الأعمال التجارية",
    "businessDesc":"نتعاون سويًا لدراسة وتحليل مشروعك ووضع الاستراتيجية والخطط التسويقية المناسبة التي تستهدف جمهورك من خلالها لتحقيق النجاحات.",
    "branding":"الهوية التجارية",
    "brandingDesc":"نصمم هويتك على أساس استراتيجي؛ لتوصل رسالتك وتعكس ما يهدف إليه عملك التجاري وتميزك عن غيرك من المنافسين وفي نفس الوقت تتمتع بمظهر جذاب.",
    "interaction":"التصميم التفاعلي",
    "interactionDesc":"نركز على تصميم المواقع والتطبيقات على أساس تجربة المستخدم لتخدم أهداف عملك؛ و تحقق تجربة سهلة للمستخدمين تجعلهم أكثر استمتاعًا وارتباطًا بعملك.",
    "development":"البرمجة",
    "developmentDesc":"نتبع أحدث التقنيات لبرمجة المواقع وتطبيقات الجوال، ونوفر الدعم اللازم لك؛ لمساعدتك على الوصول لعملائك بطريقة أسهل وبالصورة الملائمة لهويتك التجارية.",
    "animation":"الرسوم المتحركة",
    "animationDesc":"استخدم مميزات الرسوم المتحركة ثنائية وثلاثية الأبعاد؛ لتصميم المنتجات بشكلها الواقعي و إنشاء الحملات الإعلانية والأفلام القصيرة لتعبر عن هويتك وتوضح رسالتك بشكل مميز.",
    "technology":"التكنولوجيا",
    "technologyDesc":"نستخدم تقنيات الذكاء الاصطناعي و البيانات الضخمة والتحليلات وغيرها؛ لتطوير أعمالك من خلال التقنيات التكنولوجية الحديثة التي تفتح إمكانات جديدة وتسهل أداء عملك.",
    "works":"أعمالنا و شركائنا |في النجاح.",
    "fullWorks":"شاهد جميع <br>أعمالنا.",
    "labs":"المختبرات",
    "labsDesc":"الآن يمكنك الوصول إلى المزيد من الأدوات، والمقالات و الموارد المفيدة في مجالات التكنولوجيا والتصميم والبرمجة و الأعمال التجارية. ",
    "atlas":"أطلس",
    "atlasDesc":"ابدأ في بناء قاعدة معرفتك من خلال أطلس الذي يضُم المصطلحات والكلمات والاختصارات الرئيسية التي تحتاج إليها في مجالك.",
    "mentality":"تخصصات متعددة| بعقلية تهتم بالتفاصيل. ",
    "ventures":"بعض من شركائنا |وعلاماتنا التجارية. ",
    "venturesItems":{
      "1":"nllo",
      "2":"sbont",
      "3":"basb",
      "4":"sellesa",
      "5":"obka",
      "6":"halx"
    },
    "hubs":"المراكز",
    "books":"الكتب",
    "tools":"الأدوات",
    "dive":"تعمق وأعرف المزيد |في مختلف المجالات.",
    "capabilities":{
      "title":"Unleash your |full business |potentials.",
      "url":"/capabilities",
      "cta":"Our capabilities",
      "items":{
        "1":{
          "title":"حلول",
          "desc":"نوفر لك الحلول المناسبة التي تميزك عن جميع منافسيك وتقرب بينك وبين عملائك.",
          "url":"/solutions"
        },
        "2":{
          "title":"خدمات",
          "desc":"احصل على كل ما تحتاج إليه في التصميم والبرمجة والتكنولوجيا الأعمال التجارية.",
          "url":"/services"
        },
        "3":{
          "title":"صناعات",
          "desc":"خصص المنصة التي تحتاجها حسب احتياجات عملك وتمتع بمزايا لا حصر لها.",
          "url":"/industries"
        }
      }
    },
    "inside":"الداخل",
    "learn":"التعلم",
    "learnDesc":"شف شغفك في المجالات المختلفة من خلال مسارات التعلم الخاصة بنا. ",
    "academy":"الأكاديمية",
    "academyDesc":"مسارات التعلم في التصميم والأعمال التجارية والبرمجة والتكنولوجيا."
  }
}
</i18n>
